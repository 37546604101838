import { RouteLocationRaw } from 'vue-router';

export type NotificationType = 'error' | 'success' | 'warning';
export type AdvancedNotification = {
  path: string;
  args?: Record<string, unknown>;
  linkPath: string;
  routeName: RouteLocationRaw | string;
};

export const useNotificationBannerStore = defineStore('notificationBanner', () => {
  const notificationMessage = ref<AdvancedNotification | string>('');
  const notificationType = ref<NotificationType>('error');
  const notificationTimeout = ref<ReturnType<typeof setTimeout>>();

  function setNotificationBanner(message: AdvancedNotification | string, type: NotificationType, timeout = 0) {
    notificationMessage.value = message;
    notificationType.value = type;

    if (timeout) notificationTimeout.value = setTimeout(clearNotificationBanner, timeout);
  }

  function clearNotificationBanner() {
    notificationMessage.value = '';
    notificationType.value = 'error';
    clearTimeout(notificationTimeout.value);
  }

  return {
    notificationMessage,
    notificationType,
    notificationTimeout,
    setNotificationBanner,
    clearNotificationBanner,
  };
});
